import { FC } from 'react'
import styles from './Author.module.scss'
import { AuthorProps as Props } from './types'
import { CldImage } from 'next-cloudinary'

const Author: FC<Props> = ({ name, date, image }) => {
  return (
    <div className={styles['author-container']}>
      {image && <CldImage src={image} width={48} height={48} alt={name} className={styles.avatar} />}
      <div>
        <time className={styles.date}>{date}</time>
        <div className={styles.author}>{name}</div>
      </div>
    </div>
  )
}

export default Author
