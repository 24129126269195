import { useRouter } from 'next/router'
import { routes } from 'utils/routes'
import classNames from 'classnames'
import { CategoryCardProps } from '../types'
import styles from './CategoryCard.module.scss'

const CategoryCard = ({ language, category, categoryToShow }: CategoryCardProps) => {
  // Router
  const router = useRouter()

  const clearCategoryToShow = categoryToShow === category.slug
  const handleClick = () => {
    if (clearCategoryToShow) {
      router.push(routes(language).blog, undefined, { shallow: true })
    } else {
      router.push({ pathname: routes(language).blog, query: { c: category.slug } }, undefined, { shallow: true })
    }
  }

  return (
    <button
      className={classNames(styles.card, {
        [styles.active]: categoryToShow === category.slug
      })}
      onClick={handleClick}
      type="button"
    >
      {category.title}
    </button>
  )
}

export default CategoryCard
