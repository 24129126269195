import { FC } from 'react'
import Link from 'next/link'
import dayjs from 'dayjs'
import classNames from 'classnames'
import Dotdotdot from 'react-dotdotdot'
import styles from './FeaturedArticle.module.scss'
import homePageStyles from './FeaturedArticleHome.module.scss'
import { FeaturedArticleProps as Props } from './types'
import Author from 'components/author/Author'
import Tag from 'components/tag/Tag'
import { CmsArticleTypes } from 'types/cms-components'
import parse from 'html-react-parser'
import Img from 'components/img/Img'
import { Heading } from '@boltenergy-be/design-system'

const FeaturedArticleContent: FC<Props> = ({
  asContentBlock,
  author,
  category,
  description,
  destination,
  publishedAt,
  thumbnail,
  title,
  type,
  isHomeVariant
}) => {
  //determine which stylesheet to use based on where the page is presented
  const activeStyleSheet = isHomeVariant ? homePageStyles : styles

  return (
    <div
      className={classNames(activeStyleSheet['featured-article'], {
        [activeStyleSheet.borderless]: type === CmsArticleTypes.BLOG_INTERNAL,
        [activeStyleSheet['content-block']]: asContentBlock
      })}
    >
      <Link href={destination} className={activeStyleSheet.article}>
        {thumbnail && (
          <figure className={activeStyleSheet['thumbnail-wrapper']}>
            <Img
              fill
              priority
              publicId={thumbnail.provider_metadata.public_id}
              className={activeStyleSheet.thumbnail}
              style={{ objectFit: 'cover' }}
              alt={`thumbnail - ${title}`}
              sizes="(max-width: 620px) 100vw, (max-width: 1120px) 66vw, 50vw"
            />
          </figure>
        )}
        <div className={activeStyleSheet.content}>
          {category && (
            <Tag transparentBackground color={category?.color} className={activeStyleSheet.tag}>
              {category?.title}
            </Tag>
          )}
          <Heading as="h2" variant="h3">
            {title}
          </Heading>
          <Dotdotdot clamp={6} className={activeStyleSheet.description}>
            {parse(description)}
          </Dotdotdot>
          <Author date={dayjs(publishedAt).format('DD/MM/YYYY')} name={author?.firstName} image={author?.avatar?.url} />
        </div>
      </Link>
    </div>
  )
}

const FeaturedArticle: FC<Props> = ({ asContentBlock, isHomeVariant, ...props }) => {
  return (
    <section
      className={classNames('section', styles.section, {
        'is-dark': props.type === CmsArticleTypes.BLOG_INTERNAL,
        [styles.rounded]: asContentBlock
      })}
    >
      {!asContentBlock ? (
        <div className="container">
          <FeaturedArticleContent {...props} isHomeVariant={isHomeVariant} />
        </div>
      ) : (
        <FeaturedArticleContent asContentBlock={true} {...props} isHomeVariant={isHomeVariant} />
      )}
    </section>
  )
}

export default FeaturedArticle
