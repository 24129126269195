import Layout from 'components/layout/Layout'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Blog from 'features/blog/Blog'
import { getBlogLandingsPage, getBlogCategories, getBlogArticles, getExternalBlogArticles } from 'api/cms/blog'
import { DEFAULT_NAMESPACES } from 'constants/i18n'
import type { BlogProps } from 'features/blog/types'
import type { SeoProps } from 'components/seo/types'
import { sortBlogArticlesOnPublishedDate } from 'utils/articles'

const BlogPage = (props: BlogProps) => {
  // Constants
  const { seo } = props?.content
  const SEO: SeoProps = seo && {
    description: seo.metaDescription,
    image: seo.seoImage,
    title: seo.seoTitle
  }

  return (
    <Layout seo={SEO}>
      <Blog {...props} />
    </Layout>
  )
}

export const getStaticProps = async ({ locale }) => {
  try {
    // fetch Landingspage Content, Articles & Categories
    const [content, categories, blogArticles, externalArticles] = await Promise.all([
      getBlogLandingsPage(locale),
      getBlogCategories(locale),
      getBlogArticles(`locale=${locale}`),
      getExternalBlogArticles(`locale=${locale}`)
    ])

    return {
      props: {
        articles: sortBlogArticlesOnPublishedDate([...blogArticles, ...externalArticles]),
        categories,
        content,
        ...(await serverSideTranslations(locale))
      },
      revalidate: 10 // re-generate page when a request comes in, once every 10 seconds
    }
  } catch (error) {
    return {
      props: {
        articles: [],
        categories: [],
        content: [],
        projects: [],
        ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'projects']))
      },
      revalidate: 10 // re-generate page when a request comes in, once every 10 seconds
    }
  }
}

export default BlogPage
