import { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import styles from './ArticleSection.module.scss'
import ArticleCard from 'components/article/article-card/ArticleCard'
import { ArticleSectionProps } from '../types'
import { routes } from 'utils/routes'
import { CmsArticleTypes } from 'types/cms-components'
import { Button, Heading } from '@boltenergy-be/design-system'

const ArticleSection = ({ type, articles, title, className, limit, paginate }: ArticleSectionProps) => {
  // Local state
  const [amountToShow, setAmountToShow] = useState<number>(4)

  // i18n
  const { t } = useTranslation('common')

  // Constants
  const articlesToShow = useMemo(() => {
    let tempArticles: any[] = articles || []

    if (paginate) {
      tempArticles = tempArticles?.slice(0, amountToShow)
    }

    // limit the output if necessary
    if (limit && !paginate) {
      tempArticles = tempArticles?.slice(0, limit)
    }

    return tempArticles
  }, [articles, paginate, limit, amountToShow])

  // Resets the pages to show when switching category
  useEffect(() => {
    if (paginate && articles.length) {
      setAmountToShow(4)
    }
  }, [paginate, articles.length])

  return (
    <section className={classNames('container', styles['article-section'], className)}>
      {title && (
        <Heading as="h2" variant="h3">
          {title}
        </Heading>
      )}
      <ul className={styles['card-grid']}>
        {articlesToShow?.length ? (
          articlesToShow.map((article) => {
            const destination =
              type === 'encyclopedia'
                ? routes(article.locale, '', [article.category.slug, article.subCategory.slug, article.slug]).encyclopediaArticle
                : article.type === CmsArticleTypes.BLOG_INTERNAL
                  ? routes(article.locale, article.slug).blogArticle
                  : article.slug

            return (
              <li key={`${article.title}-${article.id}`}>
                <ArticleCard {...article} publishedAt={article.customPublishedAt ?? article.publishedAt} destination={destination} />
              </li>
            )
          })
        ) : (
          <li>Geen artikels gevonden</li>
        )}
      </ul>

      {paginate && (
        <div className={styles['button-group']}>
          {amountToShow > 4 && (
            <Button
              disabled={amountToShow === 4}
              className={styles['show-less']}
              onClick={() => setAmountToShow(amountToShow - 4)}
              isFullwidthMobile
              variant="tertiary"
            >
              {t('showLessArticles', 'Toon minder artikels')}
            </Button>
          )}

          <Button
            disabled={amountToShow > articlesToShow.length}
            className={styles['show-more']}
            onClick={() => setAmountToShow(amountToShow + 4)}
            isFullwidthMobile
          >
            {t('showMoreArticles', 'Toon meer artikels')}
          </Button>
        </div>
      )}
    </section>
  )
}

export default ArticleSection
